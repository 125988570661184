interface countObject {
    accessibility: any,
    performance: any,
    bestPractices: any,
    seo: any,
    [key: string]: any
}
/**
 * @param {JSON} data - jsonRequests result JSON object
 */
class Count
{
    _data: Array<any>;
    groupedData: countObject;
    runDate: string;
    finalUrl: string;
    siteImage: any | string;
    overAllScore: number;
    totalIssues: number;

    constructor(data: Array<any>)
    {
        this._data = data;
        this.totalIssues = 0;
        this.runDate = data[0].fetchTime.replace('T', ' ').replace('Z', '');
        this.finalUrl = data[0].finalUrl.replace('https', '').replace('http', '').replace(':','').replaceAll('/','');
        // This makes sure that we always get an image. Even if the first file is somehow a pdf
        for (let i = 0; i < data.length; i++) {
            this.siteImage = (data[i].audits['final-screenshot'].details || {}).data;
            if (typeof this.siteImage !== 'undefined') {
                break;
            }
        }
        this.overAllScore = 0;
        this.groupedData = this.groups();
        this._data = [];
    }

    public count()
    {
        
    }

    public score()
    {
        
    }

    public urls()
    {
        let urls: any = {}
        this._data.forEach((page) => {
            urls[page['finalUrl']] = page;
        })

        return urls;
    }

    /**
     * Setup the count object groups and categories
     * @returns {countObject}
     */
    public groups(): countObject
    {
        let groups: countObject = {
            performance:{},
            accessibility:{},
            bestPractices:{},
            seo:{}
        }

        let category: any = {};

        // Place audit categories and grab what type they are in Performance, Accessibility, Best-practices, and SEO
        let auditRefs: any = {};
        Object.keys(this._data[0].categories).forEach(key => {
            Object.keys(this._data[0].categories[key].auditRefs).forEach(key2 => {
                auditRefs[this._data[0].categories[key].auditRefs[key2].id] = (this._data[0].categories[key].auditRefs[key2]);
                auditRefs[this._data[0].categories[key].auditRefs[key2].id].type = key;
            })
        })

        // Setting up categories
        getCategories(this._data[0], 'performance');
        getCategories(this._data[0], 'accessibility');
        // There is no best practices grouping
        getCategories(this._data[0], 'bestPractices', 'best-practices');
        getCategories(this._data[0], 'seo');
        groups.performance['title'] = "Performance";
        groups.accessibility['title'] = "Accessibility";
        groups.bestPractices['title'] = "Best Practices";
        groups.seo['title'] = "Seo";
        groups.performance.groupIssueCount = 0;
        groups.accessibility.groupIssueCount = 0;
        groups.bestPractices.groupIssueCount = 0;
        groups.seo.groupIssueCount = 0;
        groups.performance.score = 0;
        groups.accessibility.score = 0;
        groups.bestPractices.score = 0;
        groups.seo.score = 0;
        let performanceScore: Array<number> = [];
        let accessibilityScore: Array<number> = [];
        let bestPracticesScore: Array<number> = [];
        let seoScore: Array<number> = [];

        // var i =0;

        this._data.forEach((page) => {
            // console.log(i);
            // i++;
            // Setup group titles

            // Declare all the finalUrl arrays
            Object.keys(page.audits).forEach(key => {

                performanceScore.push(page.categories.performance.score);
                accessibilityScore.push(page.categories.accessibility.score);
                bestPracticesScore.push(page.categories['best-practices'].score);
                seoScore.push(page.categories.seo.score);
                // Have to put the key in []'s
                if (page.audits[key].id) {

                    // Grab audit category type
                    try {
                        switch (auditRefs[page.audits[key].id].type) {
                            case 'performance':
                                // Check if it exists in a category and handle formatting the data
                                // Have to pass this so that the inner function can utilize the Count class
                                calculateGroups(this, page, key, 'performance');
                                break;

                            case 'accessibility':
                                calculateGroups(this, page, key, 'accessibility');
                                break;

                            case 'best-practices':
                                calculateGroups(this, page, key, 'bestPractices');
                                break;

                            case 'seo':
                                calculateGroups(this, page, key, 'seo');
                                break;
                        
                            default:
                                break;
                        }
                    } catch {
                        // This mainly catches the full-page-screenshot that is now apart of the audit category.
                        // It doesn't belong to a type so it kills the switch statement above.
                        // console.log(page.audits[key].id);
                    }
                }
            })
        });

        performanceScore.forEach((score) => {
            groups.performance.score += score;
        });
        groups.performance.score = Math.floor(((groups.performance.score / performanceScore.length) + Number.EPSILON) * 100) / 100;
        accessibilityScore.forEach((score) => {
            groups.accessibility.score += score;
        });
        groups.accessibility.score = Math.floor(((groups.accessibility.score / accessibilityScore.length) + Number.EPSILON) * 100) / 100;
        bestPracticesScore.forEach((score) => {
            groups.bestPractices.score += score;
        });
        groups.bestPractices.score = Math.floor(((groups.bestPractices.score / bestPracticesScore.length) + Number.EPSILON) * 100) / 100;
        seoScore.forEach((score) => {
            groups.seo.score += score;
        });
        groups.seo.score = Math.floor(((groups.seo.score / seoScore.length) + Number.EPSILON) * 100) / 100;
        this.overAllScore = Math.floor(((groups.performance.score + groups.accessibility.score + groups.bestPractices.score + groups.seo.score) / 4 + Number.EPSILON) * 100) / 100;
        return groups;

        /**
         * Calcualates the values of the 4 groups Performance, Accessibility, Best Practices, and Seo and pulls the issues into them
         * @param page 
         * @param {string} key 
         * @param {string} type category/group type: Performance, Accessibility, Best Practices, and Seo
         */
        function calculateGroups(countClass: Count, page: any, key: string, type: string): void {
            let issueCount = 0;
            // Assigned group to category
            if (typeof groups[type][auditRefs[page.audits[key].id].group] !== "undefined") {
                category = groups[type][auditRefs[page.audits[key].id].group];
            } else if (typeof groups[type].unCategorized[page.audits[key].id] === "undefined") {
                // Since the page.finalUrl isn't defined we can't go further down the array children without defining it to be blank
                groups[type].unCategorized[page.audits[key].id] = {};
            }
            // If the audit is the final screenshot then skip it. We only want to store the very first pages screenshot no others so we keep the size down.
            if (page.audits[key].id === 'final-screenshot')
            {
                return;
            }
            if (typeof category[page.audits[key].id] === 'undefined') {
                category[page.audits[key].id] = {};
            }
            if (typeof (groups[type].unCategorized || {})[page.audits[key].id] === "undefined") {
                category[page.audits[key].id][page.finalUrl] = page.audits[key];
                if (page.audits[key].score < 1 && page.audits[key].scoreDisplayMode === "binary") {
                    if (((page.audits[key].details || {}).items || {}).length > 0) {
                        // Individual issue counter for each page
                        category[page.audits[key].id][page.finalUrl].ourIssueCount = ((page.audits[key].details || {}).items || {}).length;
                        issueCount = issueCount + ((page.audits[key].details || {}).items || {}).length;
                    } else {
                        category[page.audits[key].id][page.finalUrl].ourIssueCount = 1;
                        issueCount = issueCount + 1;
                    }
                } else if (page.audits[key].score < 0.7 && page.audits[key].scoreDisplayMode === "numeric") {
                    if (((page.audits[key].details || {}).items || {}).length > 0) {
                        // Individual issue counter for each page
                        category[page.audits[key].id][page.finalUrl].ourIssueCount = ((page.audits[key].details || {}).items || {}).length;
                        issueCount = issueCount + ((page.audits[key].details || {}).items || {}).length;
                    } else {
                        category[page.audits[key].id][page.finalUrl].ourIssueCount = 1;
                        issueCount = issueCount + 1;
                    }
                } else {
                    category[page.audits[key].id][page.finalUrl].ourIssueCount = 0;
                }
                // issue counter for a specific issue across all pages
                if (category[page.audits[key].id]['IssueCount'] !== undefined) {
                    category[page.audits[key].id]['IssueCount'] = category[page.audits[key].id]['IssueCount'] + issueCount;
                } else {
                    category[page.audits[key].id]['IssueCount'] = issueCount;
                }
                // Issues for a full issue type category
                if (typeof category.issueCount !== 'undefined') {
                    category.issueCount = category.issueCount + issueCount;
                } else {
                    category.issueCount = issueCount;
                }
            } else {
                groups[type].unCategorized[page.audits[key].id][page.finalUrl] = page.audits[key];
                if (page.audits[key].score < 1 && page.audits[key].scoreDisplayMode === "binary") {
                    if (((page.audits[key].details || {}).items || {}).length > 0) {
                        // Individual issue counter for each page
                        groups[type].unCategorized[page.audits[key].id][page.finalUrl].ourIssueCount = ((page.audits[key].details || {}).items || {}).length;
                        issueCount = issueCount + ((page.audits[key].details || {}).items || {}).length;
                    } else {
                        groups[type].unCategorized[page.audits[key].id][page.finalUrl].ourIssueCount = 1;
                        issueCount = issueCount + 1;
                    }
                } else if (page.audits[key].score < 0.7 && page.audits[key].scoreDisplayMode === "numeric") {
                    if (((page.audits[key].details || {}).items || {}).length > 0) {
                        // Individual issue counter for each page
                        groups[type].unCategorized[page.audits[key].id][page.finalUrl].ourIssueCount = ((page.audits[key].details || {}).items || {}).length;
                        issueCount = issueCount + ((page.audits[key].details || {}).items || {}).length;
                    } else {
                        groups[type].unCategorized[page.audits[key].id][page.finalUrl].ourIssueCount = 1;
                        issueCount = issueCount + 1;
                    }
                } else {
                    groups[type].unCategorized[page.audits[key].id][page.finalUrl].ourIssueCount = 0;
                }
                // Global issue counter for alltypecategories together
                if (groups[type].unCategorized[page.audits[key].id]['IssueCount'] !== undefined) {
                    groups[type].unCategorized[page.audits[key].id]['IssueCount'] = groups[type].unCategorized[page.audits[key].id]['IssueCount'] + issueCount;
                } else {
                    groups[type].unCategorized[page.audits[key].id]['IssueCount'] = issueCount;
                }
                if (typeof groups[type].unCategorized.issueCount !== 'undefined') {
                    groups[type].unCategorized.issueCount = groups[type].unCategorized.issueCount + issueCount;
                } else {
                    groups[type].unCategorized.issueCount = issueCount;
                }
            }
            // Issue counter for a specific group: Performance, Accessibility, Best Practices, and Seo
            groups[type].groupIssueCount = groups[type].groupIssueCount + issueCount;
            // console.log(this);
            countClass.totalIssues = countClass.totalIssues + issueCount;
        }

        // Please for the love of god clean up the variables here
        /**
         * Grabs the auditRef categories
         * @param page the current page
         * @param type the category type
         * @param hyphenType used if the type needs a hyphen and your variables are camelCase
         */
        function getCategories(page: any, type: any, hyphenType = '' as any): void {
            if (hyphenType === '') {
                hyphenType = type;
            }
            Object.keys(page.categories[hyphenType].auditRefs).forEach(key => {
                // console.log(page[key]);
                if (typeof page.categories[hyphenType].auditRefs[key].group !== 'undefined') {
                    groups[type][page.categories[hyphenType].auditRefs[key].group] = {
                        title:page.categoryGroups[page.categories[hyphenType].auditRefs[key].group].title,
                        description:page.categoryGroups[page.categories[hyphenType].auditRefs[key].group].description
                    };
                    groups[type][page.categories[hyphenType].auditRefs[key].group][page.categories[hyphenType].auditRefs[key].id] = {};
                } else {
                    groups[type].unCategorized = {};
                    groups[type].unCategorized.title = "Uncategorized";
                    groups[type].unCategorized.description = "Audits that aren't categorized";
                }
            });
        }
    }

    /**
     * Grabs the weights for audit types and returns them in an array by their keys
     * @returns {countObject} weights
     */
    public weights(): countObject
    {
        let groups: countObject = {
            performance:{},
            accessibility:{},
            bestPractices:{},
            seo:{}
        }
        this._data.forEach((page) => {
            groups.performance['title'] = "Performance";
            page.categories.performance.auditRefs.forEach((audit: any) => {
                if (audit.id) {
                    groups['performance'][audit.id] = [audit];
                }
            })
            groups.accessibility['title'] = "Accessibility";
            page.categories.accessibility.auditRefs.forEach((audit: any) => {
                if (audit.id) {
                    groups['accessibility'][audit.id] = [audit];
                }
            })
            groups.bestPractices['title'] = "Best Practices";
            // dot notation doesn't like "-" so we go through brackets
            page.categories['best-practices'].auditRefs.forEach((audit: any) => {
                if (audit.id) {
                    groups['bestPractices'][audit.id] = [audit];
                }
            })
            groups.seo['title'] = "Seo";
            page.categories.seo.auditRefs.forEach((audit: any) => {
                if (audit.id) {
                    groups['seo'][audit.id] = [audit];
                }
            })
        });
        return groups;
    }
}

export default Count;