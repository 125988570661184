interface accordianCollapseContainer extends HTMLElement {
    isOpen?: boolean,
    height?: number,
    transition?: any
}
interface accordianTrigger extends HTMLElement {
    accordianTriggerEvent?: any
}
/**
 * @param {string} container 
 * @param {className} defaultTriggerCLass 
 * @param {className} defaultCollapseClass
 * @param {className} defaultOpenCLass 
 * @returns {void}
 */
class Accordian
{
    
    _container: string;
    _defaultTriggerClass: string;
    _defaultCollapseClass: string;
    _defaultOpenClass: string;
    _setupDone: boolean;

    constructor(container: string, defaultTriggerClass: string, defaultCollapseClass: string, defaultOpenCLass: string)
    {
        this._container = container;
        if (this._container.length === 0) {
            throw new Error("Accordion Container doesn't exist");
        }
        this._setupDone = false;
        this._defaultTriggerClass = defaultTriggerClass;
        this._defaultCollapseClass = defaultCollapseClass;
        this._defaultOpenClass = defaultOpenCLass;
        // console.log(this);

        this.setup()
    }

    private setup(): void
    {
        let accordians: NodeListOf<Element> = document.querySelectorAll(this._container);
        if (accordians.length === 0) {
            console.warn("Accordian data empty");
            return;
        }

        // console.log(accordians);

        accordians.forEach((accordian) => {
            let accordianCollapse: NodeListOf<Element> = accordian.querySelectorAll(this._defaultCollapseClass);
            if (accordianCollapse.length === 0) {
                console.warn("No Accordian collapse segments");
                return;
            }

            for(let i = accordianCollapse.length -1; i >= 0; i--){
                let ac: accordianCollapseContainer = accordianCollapse[i] as HTMLElement;
                // Check if the ac is open
                if (ac.classList.contains(this._defaultOpenClass)) {
                    ac.isOpen = true;
                    this.openAccordian(ac);
                    window.requestAnimationFrame(() => {
                        ac.style.height = "auto";
                    })
                } else {
                    ac.isOpen = false;
                    ac.style.height = "0px";
                }
            };

            let trigger: NodeListOf<HTMLElement> = accordian.querySelectorAll(this._defaultTriggerClass);
            if (trigger.length === 0) {
                console.warn("No Accordian Trigger specfied");
                return;
            }

            trigger.forEach((trigg: accordianTrigger) => {
                trigg.removeEventListener('click', trigg.accordianTriggerEvent);
                trigg.accordianTriggerEvent = this.onClick.bind(this);
                trigg.addEventListener('click', trigg.accordianTriggerEvent);
            });
        });
        this._setupDone = true;
    }

    private onClick(event: Event): void
    {
        event.stopPropagation();
        let accordianGroup: accordianCollapseContainer | null = (event.target as Element).closest(this._defaultCollapseClass);
        if (accordianGroup === null) {
            console.warn('No Accordian Groups were found for the clicked event');
            return;
        }
        //:scope allows for direct descendants only in the query selector
        let accordianCollapse: NodeListOf<Element> = accordianGroup.querySelectorAll(":scope > " + this._defaultCollapseClass);
        if (accordianCollapse.length === 0) {
            console.warn('No Accordian Collapsibles found');
        }

        for(let i = 0; i < accordianCollapse.length; i++){
            let ac: accordianCollapseContainer = accordianCollapse[i] as HTMLElement;
            ac.isOpen = !ac.isOpen;
            if (!ac.isOpen) {
                this.closeAccordian(ac);
                return;
            }
    
            if (ac.isOpen) {
                this.openAccordian(ac);
                return;
            }
        };
    }

    // public close_all() {
    // }

    public openAll()
    {
        let accordians: NodeListOf<HTMLElement> = document.querySelectorAll(this._defaultCollapseClass);
        accordians.forEach((element) => {
            element.classList.add(this._defaultOpenClass);
            element.style.height = "auto";
        });
    }

    private expand(element: accordianCollapseContainer) {
        element.removeEventListener("transitionend", element.transition);
        element.transition = function(e: Event) {
            e.stopPropagation();
            element.style.height = "auto";
            element.removeEventListener("transitionend", element.transition);
        };
        element.addEventListener('transitionend', element.transition);
        let height = element.offsetHeight;
        element.style.height = "auto";
        element.height = element.offsetHeight;
        element.style.height = height + "px";
        let maxHeight = element.height;
        if (typeof maxHeight === 'undefined') {
            maxHeight = 200;
        }
        if (!isNaN(height)) {
            while (height <= maxHeight) {
                height += 1;
                window.requestAnimationFrame(() => {
                    element.style.height = height + "px";
                });
            };
        } else {
            element.style.height = element.offsetHeight + "px";
        }
    }

    private collapse(element: accordianCollapseContainer) {
        element.removeEventListener("transitionend", element.transition);
        element.transition = (e: Event) => {
            e.stopPropagation();
            element.classList.remove(this._defaultOpenClass);
            element.removeEventListener("transitionend", element.transition);
        };
        element.addEventListener('transitionend', element.transition);
        element.height = element.offsetHeight;
        element.style.height = element.height + "px";
        window.requestAnimationFrame(() => {
            element.style.height = "0px";
        })
    }

    
    public closeAccordian(element: accordianCollapseContainer): void
    {
        if (this._setupDone)
        {
            let arrow = element?.parentElement?.querySelector('.accordian-arrow');
            arrow?.classList.remove('fa-chevron-up');
            arrow?.classList.add('fa-chevron-down');
        }
        this.collapse(element);
    }

    public openAccordian(element: accordianCollapseContainer): void
    {
        element.classList.add(this._defaultOpenClass);
        if (this._setupDone)
        {
            let arrow = element?.parentElement?.querySelector('.accordian-arrow');
            arrow?.classList.remove('fa-chevron-down');
            arrow?.classList.add('fa-chevron-up');
        }
        this.expand(element);
    }

    public reset(): void
    {
        this.setup();
    }
}
export default Accordian;