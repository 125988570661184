import React from "react";
import Card from "./Card";

interface congratsProps
{
    children?: React.ReactNode;
}
interface congratsState {
}

interface CongratsBox
{
}

class CongratsBox extends React.Component<congratsProps, congratsState>
{
    render(): React.ReactNode
    {
        return (
            <Card classes="congratsBox container">
                <h2 className="fs-30" style={{color: "white", fontWeight: "900"}}>The journey to SEO excellence starts with one scan - and you just did it!</h2>
                <p style={{fontSize: "22px", color: "white"}}>
                    <b>Congratulations on taking the first step towards improving your website!</b> By running a scan, you've shown a dedication to optimizing and improving your online presence. We know it can be overwhelming to manage your website, but you've made a great start. The insights you'll gain from this scan will help you identify areas for improvement and take action to boost your search engine rankings, drive traffic, and increase conversions. <b>Thank you for choosing our tool!</b>
                    <br />
                    <span style={{marginLeft: "140px", color: "white"}}><b>Our analyser scans every section of your website and can take up to 15 min.</b> We know that your time is valuable, which is why we've included a loading circle to keep you informed on the progress of your website scan. <b>You don't have to sit and watch the loading circle.</b> You're free to navigate away from this page and come back later when it's done. All you need to do is log back in and go to the report through the dashboard.</span>
                </p>
            </Card>
        )
    };
}

export default CongratsBox;