import React from "react";
import Count from './ts/count';
import ReportCard from "./ReportCard";

interface seoProps {
    data: Count;
    setReportState: Function;
    openedReport: string;
}
interface seoState
{
}

class ReportOverview extends React.Component<seoProps, seoState>
{
    render(): React.ReactNode
    {
        return (
            <div className="reportOverview">
                <ReportCard openedReport={this.props.openedReport} data={this.props.data} type="performance" setReportState={this.props.setReportState} />
                <ReportCard openedReport={this.props.openedReport} data={this.props.data} type="accessibility" setReportState={this.props.setReportState} />
                <ReportCard openedReport={this.props.openedReport} data={this.props.data} type="bestPractices" setReportState={this.props.setReportState} />
                <ReportCard openedReport={this.props.openedReport} data={this.props.data} type="seo" setReportState={this.props.setReportState} />
            </div>
        );
    };
}

export default ReportOverview;