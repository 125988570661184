import React from "react";
import Progress from './ts/progress';
import GlobalContext from "./GlobalContext";

interface LoadingProps {
    width: string;
    height: string;
    text: string;
    color: string;
}
interface LoadingState {}

interface Loading
{
    loader: Progress;
}

class Loading extends React.Component<LoadingProps, LoadingState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    static defaultProps = {
        width: '200px',
        height: '200px',
        text: "Loading",
        color: '#000000'
    };

    // Specifies the default values for props:
    componentDidMount()
    {
        this.loader = new Progress('loadingSubContainer', 'loader', {
            duration: 1000,
            svgStyle: { 
              width: this.props.width,
              height: this.props.height
            },
            color: this.props.color,
            strokeWidth: 6
        });
        this.loader.show();
        this.loader.setText(this.props.text as string);
        this.fakeLoadingTimer();
    }

    componentDidUpdate()
    {
        this.fakeLoadingTimer();
    }

    fakeLoadingTimer = () =>
    {
        if (this.context.fakeLoading) {
            setTimeout(() => {
                this.context.setGlobalState({loading: false, fakeLoading: false});
            }, 1000);
        }
    }

    componentWillUnmount()
    {
        this.loader.hide();
    }

    render(): React.ReactNode
    {
        return (
            <div className="loadingContainer">
                <div className="loadingSubContainer">

                </div>
            </div>
        );
    };
}

export default Loading;