import React from "react";
import axios from "axios";
import GlobalContext from "./GlobalContext";
import Loading from "./Loading";
import ListDisplayOnly from "./ListDisplayOnly";
import ScanUI from "./ScanUI";

interface ContactProps {
}
interface ContactState {
    loading: boolean;
}

interface Contact
{
    abController: AbortController;
}

class Contact extends React.Component<ContactProps, ContactState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    constructor(props: ContactProps)
    {
        super(props);
        this.state = {
            loading: false
        };
    }

    abortSiteQuerys = () =>
    {
        if (this.abController) {
            this.abController.abort();
        }
    }

    email = () =>
    {
        this.setState({loading: true});
        this.abortSiteQuerys();
        this.abController = new AbortController();
        let data: FormData = new FormData();
        let name: HTMLInputElement | null = document.querySelector('input[name="name"]');
        let companyName: HTMLInputElement | null = document.querySelector('input[name="companyName"]');
        let email: HTMLInputElement | null = document.querySelector('input[name="email"]');
        let seo: HTMLInputElement | null = document.querySelector('input[name="SEO"]');
        let sem: HTMLInputElement | null = document.querySelector('input[name="SEM"]');

        if (!email || !companyName || !name) {
            return;
        }

        data.append('name', name.value);
        data.append('companyName', companyName.value);
        data.append('email', email.value);
        if (seo && seo.checked) {
            data.append('seo', 'Search Engine Optimization');
        }
        if (sem && sem.checked) {
            data.append('sem', 'Search Engine Marketing');
        }

        axios.post(process.env.REACT_APP_ROOT + '/php/contact/contact', data, {signal: this.abController.signal,withCredentials: true}).then(response => {
            let errors: NodeListOf<HTMLElement> | null = document.querySelectorAll('.error');
            for (let i = 0; i < errors.length; i++) {
                errors[i].remove();
            }
            // console.log(response.data);
            if (response.data.success) {
                window.dataLayer.push({'event' : 'form_submit'});
                this.setState({loading: false});
                let contactInnerContainer = document.querySelector('.contactInnerContainer');
                contactInnerContainer?.insertAdjacentHTML('afterbegin', '<div class="thank-you">' + response.data.success + '</div>');
            }
            if (response.data.error) {
                this.setState({loading: false});
                let contactInnerContainer = document.querySelector('.contactInnerContainer');
                contactInnerContainer?.insertAdjacentHTML('afterbegin', '<div class="error">' + response.data.error + '</div>');
            }
        }).catch(error => {
            console.error(error);
        });
    }
    
    render(): React.ReactNode
    {
        return (
            <div className="reportContainer">
                <div id="container" className="flex column flex-w-center flex-h-center">
                    <div className="contactContainer">
                        <div className="contactInnerContainer flex column">
                            <h2 className="fs-20">Please fill out the contact form below and one of our Digital Marketing Specialists will contact you in 24 Hrs!</h2>
                            <br />
                            <label htmlFor="name" className="loginLabel">Site</label>
                            <ScanUI listDisplayOnly={true} />
                            <br />
                            <label htmlFor="companyName" className="loginLabel">Company Name</label>
                            <input type="text" id="companyName" name="companyName" className="loginInput"></input>
                            <label htmlFor="name" className="loginLabel">Contact</label>
                            <input type="text" id="name" name="name" className="loginInput"></input>
                            <label htmlFor="email" className="loginLabel">E-Mail</label>
                            <input type="text" id="email" name="email" className="loginInput"></input>
                            <div className="text-left">
                                <input type="checkbox" name="SEO" className="inline"></input> <span className="inline">Search Engine Optimization</span>
                            </div>
                            <div className="text-left">
                                <input type="checkbox" name="SEM" className="inline"></input> <span className="inline">Search Engine Marketing</span>
                            </div>
                            <button onClick={this.email} className="confirmationButton mx-auto">{this.state.loading && <Loading width="19px" height="19px" text="" color="black" />} {!this.state.loading && "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Contact;