import axios from "axios";
import React from "react";
import Progress from './ts/progress';
import GlobalContext from './GlobalContext';
import CongratsBox from "./CongratsBox";

interface CheckingProps
{
    setSelectedSite: Function;
    width: string;
    height: string;
    text: string;
    color: string;
}
interface CheckingState {}

interface Checking
{
    loader: Progress;
    abController: AbortController;
    checkLoop: NodeJS.Timer;
}

class Checking extends React.Component<CheckingProps, CheckingState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    static defaultProps = {
        width: '200px',
        height: '200px',
        text: "Loading",
        color: '#000000'
    };
    componentDidMount()
    {
        this.abController = new AbortController();
        this.loader = new Progress('loadingSubContainer', 'loader', {
            duration: 1000,
            svgStyle: { 
              width: this.props.width,
              height: this.props.height
            },
            color: this.props.color,
            strokeWidth: 6,
            text: {value: "Scanning<br>0%"}
        });
        this.loader.show();
        this.checkLoop = setInterval(() =>{
            axios.get(process.env.REACT_APP_ROOT + '/php/report/check?url=' + this.context.selectedSite + '&userid=' + this.context.userid, {signal: this.abController.signal}).then(response => {
                // console.log(response);
                if (typeof response.data.percent === 'undefined') {
                    console.warn("No Percent Found!");
                    return;
                }
                if (response.data.percent === "100%") {
                    this.props.setSelectedSite(this.context.selectedSite);
                }
                this.loader.setText("Scanning<br>" + response.data.percent);
                // let logDiv = document.querySelector('.log');
                // if (logDiv === null) {
                //     console.warn("No Log Div Found!");
                //     return;
                // }
                // if (typeof response.data.log !== 'undefined') {
                //     logDiv.innerHTML = "<div>" + response.data.log.reverse().join("<br>") + "</div>";
                // }
            }).catch(error => {
                console.error(error);
            })
        }, 1000);
    }

    componentWillUnmount()
    {
        clearInterval(this.checkLoop);
        this.loader.hide();
        this.abController.abort();
    }

    render(): React.ReactNode
    {
        return (
            <div>
                <div className="checkingContainer section">
                    <CongratsBox />
                </div>
                <div className="loadingContainer section">
                    <div className="loadingSubContainer">
                        {/* <div className="log"></div> */}
                    </div>
                </div>
            </div>
        );
    };
}

export default Checking;