import React from "react";
import axios from "axios";
import GlobalContext from './GlobalContext';
import Loading from "./Loading";

interface RegisterProp {}
interface RegisterState {
    loading: boolean
}

interface Register {
    abController: AbortController;
}

class Register extends React.Component<RegisterProp, RegisterState>
{   
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    constructor(props: RegisterProp)
    {
        super(props);
        this.state = {
            loading: false
        };
    }
    componentDidMount()
    {
        this.context.setGlobalState({loading: false});
    }

    abortSiteQuerys = () =>
    {
        if (this.abController) {
            this.abController.abort();
        }
    }

    authenticate = () =>
    {
        this.abController = new AbortController();
        let data: FormData = new FormData();
        let email: HTMLInputElement | null = document.querySelector('input[name="email"]');
        let password: HTMLInputElement | null = document.querySelector('input[name="password"]');
        let confirmPassword: HTMLInputElement | null = document.querySelector('input[name="confirmPassword"]');

        if (!email || !password || !confirmPassword) {
            return;
        }

        data.append('email', email.value);
        data.append('password', password.value);
        data.append('confirmPassword', confirmPassword.value);

        this.setState({loading: true});

        axios.post(process.env.REACT_APP_ROOT + '/php/auth/create', data, {signal: this.abController.signal,withCredentials: true}).then(response => {
            let errors: NodeListOf<HTMLElement> | null = document.querySelectorAll('.error');
            for (let i = 0; i < errors.length; i++) {
                errors[i].remove();
            }
            // console.log(response.data);
            if (response.data.success) {
                this.setState({loading: false});
                this.context.setGlobalState({loggedIn: true, register: false, userid: response.data.userid});
                window.dataLayer.push({'event': 'sign_up'});
            }
            if (response.data.error) {
                this.setState({loading: false});
                let loginInnerContainer = document.querySelector('.loginInnerContainer');
                loginInnerContainer?.insertAdjacentHTML('afterbegin', '<div class="error">' + response.data.error + '</div>');
            }
            // this.setState({sites:response.data});
        }).catch(error => {
            console.error(error);
        });
    }

    componentWillUnmount()
    {
        this.abortSiteQuerys();
    }

    togglePassword = () =>
    {
        let passwordField: HTMLElement | null = document.querySelector('#password');
        let confirmPasswordField: HTMLElement | null = document.querySelector('#confirmPassword');
        if (passwordField !== null && confirmPasswordField !== null)
        {
            if (passwordField.getAttribute('type') === 'text')
            {
                confirmPasswordField.setAttribute('type', 'password');
                passwordField.setAttribute('type', 'password');
                return;
            }
            if (passwordField.getAttribute('type') === 'password')
            {
                passwordField.setAttribute('type', 'text');
                confirmPasswordField.setAttribute('type', 'text');
                return;
            }
        }
    }

    login = () =>
    {
        this.context.setGlobalState({fakeLoading: true, login: true, register: false});
    }

    render(): React.ReactNode {
        return (
            <div id="container" className="flex column flex-w-center flex-h-center">
                <div className="loginContainer">
                    <div className="loginInnerContainer flex column">
                        <h1>Create an Account</h1>
                        <label htmlFor="email" className="loginLabel">E-Mail</label>
                        <input id="email" name="email" className="loginInput"></input>
                        <div className="passwordContainer flex column">
                            <label htmlFor="password" className="loginLabel">Password</label>
                            <input type="password" id="password" name="password" className="loginInput"></input>
                            <button onClick={this.togglePassword} className="togglePassword" tabIndex={-1}><i className="fa fa-eye"></i></button>
                        </div>
                        <div className="passwordContainer flex column">
                            <label htmlFor="confirmPassword" className="loginLabel">Confirm Password</label>
                            <input type="password" id="confirmPassword" name="confirmPassword" className="loginInput"></input>
                            <button onClick={this.togglePassword} className="togglePassword" tabIndex={-1}><i className="fa fa-eye"></i></button>
                        </div>
                        <button onClick={this.authenticate} className="confirmationButton mx-auto">{this.state.loading && <Loading width="19px" height="19px" text="" color="black" />} {!this.state.loading && "Submit"}</button>
                        <br />
                        <button onClick={this.login} className="mx-auto fake-link">Already have an account?<br />Click Here to Login!</button>
                    </div>
                </div>
            </div>
        );
    };
}

export default Register;