import React from "react";

interface cardProps
{
    children?: React.ReactNode;
    header?: string;
    message?: string;
    classes?: string;
}
interface cardState {
    openedcard: string;
}

interface Card
{
}

class Card extends React.Component<cardProps, cardState>
{
    render(): React.ReactNode
    {
        return (
            <div className={"card " + this.props.classes}>
                {this.props.header && <h2>{this.props.header}</h2>}
                {this.props.message && <p>{this.props.message}</p>}
                {this.props.children}
            </div>
        )
    };
}

export default Card;