import React from "react";

interface congratsProps
{
    children?: React.ReactNode;
}
interface congratsState {
}

interface CongratsScanDone
{
}

class CongratsScanDone extends React.Component<congratsProps, congratsState>
{
    render(): React.ReactNode
    {
        return (
            <div className="reportCallout">
                <h2>Congratulations on completing your website scan!</h2>
                <p>
                    The insights you've gained from this report can help you improve your online presence and achieve greater success with your website. 
                    <ul className="listIndent">
                        <li>If you do not have the expertise to make the changes to your website to maximize the information we have provided, you can reach out to us.</li>
                        <li>Synergy Web DM is a <b>Google 5 star</b> rated Web Design and Digital Marketing company.</li>
                        <li>Google and Microsoft Partner</li>
                        <li>Our team of experts will work to improve and optimize your site for search engines.</li>
                        <li>Help you achieve a higher search engine rankings, drive more traffic to your site, and increase conversions.</li>
                    </ul>
                </p>
            </div>
        )
    };
}

export default CongratsScanDone;