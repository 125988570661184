import React from "react";
import Chart, { ArcProps } from 'chart.js/auto';
import GlobalContext from "./GlobalContext";

interface reportGraphsProps
{
    data: any;
}
interface reportGraphsState {}

interface ReportGraphs
{
    chart: Array<Chart>;
}

class ReportGraphs extends React.Component<reportGraphsProps, reportGraphsState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    componentDidMount()
    {
        if (Object.keys(this.props.data).length > 0)
        {
            let performanceChartDiv: HTMLCanvasElement = document.getElementById('performanceChart') as HTMLCanvasElement;
            let accessibilityChartDiv: HTMLCanvasElement = document.getElementById('accessibilityChart') as HTMLCanvasElement;
            let bestPracticesChartDiv: HTMLCanvasElement = document.getElementById('bestPracticesChart') as HTMLCanvasElement;
            let seoChartDiv: HTMLCanvasElement = document.getElementById('seoChart') as HTMLCanvasElement;
            // Types are performance, accessibility, bestPractices, seo
            this.createChart(performanceChartDiv.getContext('2d'), 'performance', 'Performance');
            this.createChart(accessibilityChartDiv.getContext('2d'), 'accessibility', 'Accessibility');
            this.createChart(bestPracticesChartDiv.getContext('2d'), 'bestPractices', 'Best Practices');
            this.createChart(seoChartDiv.getContext('2d'), 'seo', 'SEO');
            // console.log(this.chart);
        }
    }

    componentDidUpdate(prevProps: Readonly<reportGraphsProps>, prevState: Readonly<reportGraphsState>, snapshot?: any): void {
        this.clearCharts();
        if (Object.keys(this.props.data).length > 0)
        {
            let performanceChartDiv: HTMLCanvasElement = document.getElementById('performanceChart') as HTMLCanvasElement;
            let accessibilityChartDiv: HTMLCanvasElement = document.getElementById('accessibilityChart') as HTMLCanvasElement;
            let bestPracticesChartDiv: HTMLCanvasElement = document.getElementById('bestPracticesChart') as HTMLCanvasElement;
            let seoChartDiv: HTMLCanvasElement = document.getElementById('seoChart') as HTMLCanvasElement;
            // Types are performance, accessibility, bestPractices, seo
            this.createChart(performanceChartDiv.getContext('2d'), 'performance', 'Performance');
            this.createChart(accessibilityChartDiv.getContext('2d'), 'accessibility', 'Accessibility');
            this.createChart(bestPracticesChartDiv.getContext('2d'), 'bestPractices', 'Best Practices');
            this.createChart(seoChartDiv.getContext('2d'), 'seo', 'SEO');
            // console.log(this.chart);
        }
    }

    createChart = (chartDiv: CanvasRenderingContext2D | null, type: string, title: string) =>
    {
        if (chartDiv === null)
        {
            throw new Error('Canvas context is null');
        }
        const data = this.props.data.groupedData;
        let color;
        if (data[type].score >= 0.9)
        {
            color = 'rgb(75, 255, 192)';
        }
        else if (data[type].score >= 0.5)
        {
            color = 'rgb(255, 150, 0)';
        }
        else
        {
            color = 'rgb(255, 99, 132)';
        }
        const plugin =
        {
            beforeDraw: (chart: Chart) =>
            {
                const ctx: CanvasRenderingContext2D | null = chart.canvas.getContext('2d');
                if (ctx === null)
                {
                    throw new Error('Canvas context is null');
                }
                const arc: ArcProps = chart.getDatasetMeta(0).data[0] as unknown as ArcProps;
                const x = chart.chartArea.right / 2;
                const y = chart.chartArea.bottom / 2;
                const radius = arc.outerRadius - 7;
                ctx.save();
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, 2 * Math.PI);
                ctx.strokeStyle = 'white';
                ctx.lineWidth = 15;
                ctx.stroke();
                ctx.restore();
            },
            afterDraw: (chart: Chart) =>
            {
                const ctx: CanvasRenderingContext2D | null = chart.canvas.getContext('2d');
                if (ctx === null)
                {
                    throw new Error('Canvas context is null');
                }
                const x = chart.chartArea.right / 2;
                const y = chart.chartArea.bottom / 2;
                ctx.save();
                ctx.beginPath();
                ctx.font = ctx.font.replace(/\d+px/, "30px");
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';
                ctx.fillStyle = 'black';
                ctx.fillText((Math.floor(data[type].score * 100) + "%"), x, y)
                ctx.beginPath();
                if (window.innerWidth < 400) {
                    ctx.font = ctx.font.replace(/\d+px/, "14px");
                } else {
                    ctx.font = ctx.font.replace(/\d+px/, "20px");
                }
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';
                ctx.fillStyle = 'black';
                ctx.fillText(title, x, y + 30)
                ctx.restore();
            },
            beforeEvent: (chart: Chart, arg: any, options: any) =>
            {
                return false; // disable all events
            }
        }

        if (this.chart === null || typeof this.chart === 'undefined')
        {
            this.chart = [];
        }

        if (typeof data !== 'undefined' && data !== null)
        {
            if (typeof chartDiv !== 'undefined')
            {
                const chartSettings =
                {
                    labels: [type, 'hide'],
                    datasets:
                    [{
                        label: 'My First Dataset',
                        data: [data[type].score, (1 - data[type].score)],
                        backgroundColor:
                        [
                            color,
                            'white'
                        ],
                        hoverOffset: 4
                    }],
                };
                let config: any =
                {
                    type: 'doughnut',
                    data: chartSettings,
                    plugins: [plugin],
                    options:
                    {
                        cutout: '90%',
                        radius: '90%',
                        plugins:
                        {
                            legend:
                            {
                                display: false
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    },
                }
    
                this.chart.push(new Chart(chartDiv, config));
            }
        }
    }
    
    componentWillUnmount(): void
    {
        this.clearCharts();
    }

    clearCharts(): void
    {
        // If a chart exists destroy it when the component unmounts
        if (typeof this.chart !== 'undefined' && this.chart !== null)
        {
            this.chart.forEach((chart, index) =>
            {
                chart.destroy();
            });
            this.chart = [];
        }
    }

    render(): React.ReactNode
    {
        return (
            <div className="chartGrid">
                {/* Each Canvas needs a dedicated container that you style sizes for to be responsive with Chart.js */}
                <div style={{width: "95%", position: 'relative', margin: 'auto'}}>
                    <canvas id="performanceChart"></canvas>
                </div>
                <div style={{width: "95%", position: 'relative', margin: 'auto'}}>
                    <canvas id="accessibilityChart"></canvas>
                </div>
                <div style={{width: "95%", position: 'relative', margin: 'auto'}}>
                    <canvas id="bestPracticesChart"></canvas>
                </div>
                <div style={{width: "95%", position: 'relative', margin: 'auto'}}>
                    <canvas id="seoChart"></canvas>
                </div>
            </div>
        );
    };
}

export default ReportGraphs;