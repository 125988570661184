import React from "react";
import ReportContent from "./ReportContent";
import ReportOverview from "./ReportOverview";
import Accordian from './ts/accordian';
import ReportGraphs from "./ReportGraphs";
import GlobalContext from "./GlobalContext";
import IsEmpty from "./ts/isEmpty";
import ReportSiteImage from "./ReportSiteImage";
import ReportOverAllScore from "./ReportOverAllScore";
import ScanUI from "./ScanUI";
import ScanControls from "./ScanControls";
import imgSummary from "./images/audit_summary.png";
import imgAudit from "./images/audit_details.png";
import Card from "./Card";
import Grid from "./Grid";
import List from "./List";
import Contact from "./Contact";
import MarketingAdBox from "./MarketingAd";

interface dashboardProps
{
}
interface dashboardState {
}

interface Dashboard
{
}

class Dashboard extends React.Component<dashboardProps, dashboardState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    render(): React.ReactNode
    {
        return (
            <div className="reportContainer">
                <div className="informationalContainer flex column section reportContainer">
                    <div className="flex flex-wrap">
                        <div className="w-100">
                            {/* <h1 className="callout text-center container">Synergy SEO Auditing Tool</h1> */}
                            {/* <h2 className="calloutHeader text-center container">Your Dashboard</h2> */}
                            <p className="text-center" style={{fontSize: "25px", fontWeight: "900"}}>You can use the dropdown below to access your existing reports</p>
                            <ScanUI listOnly={true} />
                            <p className="website-name w-100 text-center" style={{fontSize: "25px", fontWeight: "900"}}>
                                <span style={{fontSize: "30px", fontWeight: "900", color: "#778d28"}}>OR</span> <br />
                                Run a new Audit
                            </p>
                            <ScanControls />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="container text-left">
                        <MarketingAdBox />
                    </div>
                </div>
                <div className="section">
                    <Contact />
                </div>
            </div>
        );
    };
}

export default Dashboard;