import React from "react";
import ReportContent from "./ReportContent";
import ReportOverview from "./ReportOverview";
import Accordian from './ts/accordian';
import ReportGraphs from "./ReportGraphs";
import GlobalContext from "./GlobalContext";
import IsEmpty from "./ts/isEmpty";
import ReportSiteImage from "./ReportSiteImage";
import ReportOverAllScore from "./ReportOverAllScore";
import ScanUI from "./ScanUI";
import ScanControls from "./ScanControls";
import imgSummary from "./images/audit_summary.png";
import imgAudit from "./images/audit_details.png";
import Card from "./Card";
import Grid from "./Grid";
import Contact from "./Contact";
import MarketingAdBox from "./MarketingAd";
import CongratsScanDone from "./CongratsScanDone";

interface reportProps
{
    data: any;
}
interface reportState {
    openedReport: string;
}

interface Report
{
    accordian: Accordian;
}

class Report extends React.Component<reportProps, reportState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    constructor(props: reportProps)
    {
        super(props);
        this.state = {
            openedReport: 'performance'
        };
    }
    componentDidMount()
    {
        this.accordian = new Accordian('.reportContent', '.collapse-controller', '.collapse-group', 'open');
        window.addEventListener('beforeprint', this.handlePrint);
        window.addEventListener('afterprint', this.handlePrint);
    }
    
    componentDidUpdate()
    {
        this.accordian = new Accordian('.reportContent', '.collapse-controller', '.collapse-group', 'open');
        if (this.context.printMode === true) {
            this.accordian.openAll();
        }
        window.removeEventListener('beforeprint', this.handlePrint);
        window.removeEventListener('afterprint', this.handlePrint);
    }

    setReportState = (type: string): void =>
    {
        this.setState({
            openedReport: type
        });
        this.context.setGlobalState({printMode: false});
    }

    handlePrint = (event: Event) =>
    {
        if (event.type === 'beforeprint') {
            this.clearReportState();
        }
    }

    clearReportState = (): void =>
    {
        this.setState({
            openedReport: ''
        });
    }

    componentWillUnmount(): void
    {
    }

    localizeTime(): string
    {
        let dateObj = new Date(this.props.data.runDate);
        // Take the date which is in a local timezone and convert it to UTC
        dateObj = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), dateObj.getHours(), dateObj.getMinutes(), dateObj.getSeconds()));
        let date = dateObj.toLocaleDateString();
        let time = dateObj.toLocaleTimeString();
        return date + " " + time;
    }

    render(): React.ReactNode
    {
        if (!IsEmpty(this.props.data))
        {
            return (
                <div className="reportContainer bg-offwhite">
                    <div className="container w-100">
                        { this.context.printMode === false &&
                            <CongratsScanDone />
                        }
                        <div className="reportHeader">
                            <h1>
                                <span>Audit results for {this.props.data.finalUrl}</span>
                                <span>Audit ran on {this.localizeTime()}</span>
                            </h1>
                        </div>
                        <div className="reportContent">
                            <div className="flex flex-wrap container">
                                <ReportOverAllScore data={this.props.data} />
                                <ReportSiteImage data={this.props.data} />
                            </div>
                            <ReportGraphs data={this.props.data} />
                            <ReportOverview openedReport={this.state.openedReport} data={this.props.data} setReportState={this.setReportState} />
                            <div className="reportExpandedBox">
                                <ReportContent data={this.props.data} type={this.state.openedReport} setReportState={this.setReportState} />
                            </div>
                            { this.context.printMode === true &&
                                <div>
                                    <ReportContent data={this.props.data} type="performance"  setReportState={this.setReportState} />
                                    <ReportContent data={this.props.data} type="accessibility"  setReportState={this.setReportState} />
                                    <ReportContent data={this.props.data} type="bestPractices"  setReportState={this.setReportState} />
                                    <ReportContent data={this.props.data} type="seo"  setReportState={this.setReportState} />
                                </div>
                            }
                        </div>
                    </div>
                    { this.context.printMode === false &&
                            <div className="section">
                                <div className="container text-left">
                                    <MarketingAdBox />
                                </div>
                            </div>
                        }
                        { this.context.printMode === false && <div className="section"><Contact /></div> }
                </div>
            );
        }
        return (
            <div className="reportContainer">
                <div className="informationalContainer flex column">
                    <div className="flex flex-wrap">
                        <div className="section w-100 mainInfo">
                            <div className="container">
                                <Grid columns={2} rowGap={"10px"}>
                                    <div className="calloutContainer">
                                        <h2 className="calloutHeader">Fully Featured Website Audit</h2>
                                        <p className="fs-30">
                                            Check your website for over 100 different issues that can affect your website's <b>Performance</b>, <b>Accessibility</b>, <b>Best Practices</b>, and <b>SEO</b>.
                                            You will receive a detailed report that will show you exactly what issues were found and how to fix them.
                                        </p>
                                        <h2 className="callout text-center container">Utilize our SEO Tool to improve your website's Performance and Quality Score</h2>
                                    </div>
                                    <div className="flex">
                                        <div className="priceSpecList mx-auto">
                                            <h2>For only $9.99!</h2>
                                            <ul>
                                                <li><span className="fa fa-check"></span>Audit your entire website with one scan</li>
                                                <li><span className="fa fa-check"></span>View your SEO Audit Report online</li>
                                                <li><span className="fa fa-check"></span>Print your SEO Audit Report</li>
                                                <li><span className="fa fa-check"></span>Find areas for improvments</li>
                                                <li><span className="fa fa-check"></span>In-depth insights for<br />
                                                    <span className="listIndent"><span className="fa fa-circle"></span>Performance</span>
                                                    <span className="listIndent"><span className="fa fa-circle"></span>Accessibility</span>
                                                    <span className="listIndent"><span className="fa fa-circle"></span>Search Engine Optimization</span>
                                                    <span className="listIndent"><span className="fa fa-circle"></span>Best Practices</span>
                                                </li>
                                                <li><span className="fa fa-check"></span>1-100 Grading System</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                        <div className="section w-100 bg-green">
                            <ScanControls />
                        </div>
                        <div className="section w-100">
                            <div className="container">
                                <Grid columns={2} rowGap={"10px"}>
                                    <div>
                                        <h2>Detailed Breakdown of Four Categories</h2>
                                        <p>
                                            In your Audit report you can see a detailed breakdown of the four categories that are used to determine your overall
                                            score. <b>Performance</b>, <b>Accessibility</b>, <b>Best Practices</b>, and <b>Seo</b>. Each category is broken down into subcategories that are scored individually.
                                            You can see the score for each subcategory as well as a list of the issues that were found. This will allow you to target specific areas of your
                                            website that need improvement.
                                        </p>
                                    </div>
                                    <img src={imgSummary} alt="SEO Audit" className="img-fluid img-bg-grey align-self-center"  />
                                </Grid>
                            </div>
                        </div>
                        <div className="section w-100">
                            <div className="flex flex-wrap container mx-auto">
                                <h2 className="text-center w-100">Why should you improve your Website's Score?</h2>
                                <p>
                                    Not only is it vital to rank well in the search engines, but your website's usability and overall experience should be a good one,
                                    no matter how your visitors end up there.
                                </p>
                                <Grid columns={2}>
                                    <Card header={"Website Performance Matters"} message={"Search Engines are interested in websites that perform well and page speed is a ranking factor. No one enjoys visiting a slow loading website and even the smallest delays can have a negative impact on your business goals.Incremental improvments, when scaled up, can result in large increases in revenue."} />
                                    <Card header={"Website Best Practice Matters"} message={"Avoiding the use of deprecated technologies means your website will work more consistently across modern and future browsers. Security isn't just important for websites that have forms or accept online payments. Google Chrome and other popular browsers now warn users if they are visiting a page that is not protected by HTTPS. Website visitors need to trust the site they are visting."} />
                                    <Card header={"Website SEO Matters"} message={"The higher your website appears on a search engine page. the more visitors your website will have. Search engines have the capability to generate more leads than any other marketing avenue, and most online experiences generally start with a search engine."} />
                                    <Card header={"Website Accessibility Matters"} message={"Making your website accessible to all users, including those with disabilities, increases your potential audience and ensures indexing by search engines. If your website isn't accessible to screen readers, chances are that it won't be index-able by search engines either."} />
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Report;