interface appState
{
    data: any;
    loading: boolean;
    checking: boolean;
    selectedSite: String;
    loggedIn: boolean;
    register: boolean;
    userid: number | null;
    homePage: boolean;
    darkMode: boolean;
    buyScans: boolean;
}
/**
 * @returns {History}
 */
class History
{
    
    _currentHistoryIndex: number;
    _maxHistoryIndex: number;
    _historyStates: Array<object>;

    constructor(state: object)
    {
        this._currentHistoryIndex = 0;
        this._maxHistoryIndex = 0;
        this._historyStates = [state];
        // console.log(this);
    }

    public push(state: object): void
    {
        this._historyStates.push(state);
        this._currentHistoryIndex = this._maxHistoryIndex + 1;
        this._maxHistoryIndex++;
        this.get();
    }

    public get(stateIndex: number = this._currentHistoryIndex): appState | object | void
    {
        if (typeof this._historyStates[stateIndex] !== 'undefined') {
            return this._historyStates[stateIndex];
        } else {
            console.warn("State history not found at supplied index");
        }
    }

    public go(stateIndex: number = 0): object | void
    {
        if (typeof this._historyStates[stateIndex] !== 'undefined') {
            this._currentHistoryIndex = stateIndex;
            return this.get(stateIndex);
        } else {
            console.warn("State history not found at supplied index");
        };
    }
}
export default History;