import React from "react";
import Count from './ts/count';
import GlobalContext from "./GlobalContext";

interface seoProps
{
    data: Count;
    type: String;
    setReportState: Function;
    openedReport: string;
}
interface seoState
{
}

class ReportCard extends React.Component<seoProps, seoState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    render(): React.ReactNode
    {
        if (Object.keys(this.props.data).length > 0 && (this.props.type !== undefined && (this.props.type === "performance" || this.props.type === "accessibility"  || this.props.type === "bestPractices"  || this.props.type === "seo" ))) {
            let groups = this.props.data.groupedData;
            let type = this.props.type;
            if (this.props.openedReport === type) {
                return (
                    <div className="reportCard active" onClick={() => {this.props.setReportState(type)}}>
                        <div className="header">
                            <h1>{groups[type as keyof typeof groups].title}</h1>
                        </div>
                        <div className="issues">
                            <p>{groups[type as keyof typeof groups].groupIssueCount} Issues</p>
                        </div>
                    </div>
                );
            }
            return (
                <div className="reportCard" onClick={() => {this.props.setReportState(type)}}>
                    <div className="header">
                        <h1>{groups[type as keyof typeof groups].title}</h1>
                    </div>
                    <div className="issues">
                        <p className="print-score">Score: {groups[type as keyof typeof groups].score * 100}%</p>
                        <p>{groups[type as keyof typeof groups].groupIssueCount} Issues</p>
                    </div>
                </div>
            );
        }
    };
}

export default ReportCard;