import React from "react";
import Card from "./Card";

interface marketingAdProps
{
    children?: React.ReactNode;
}
interface marketingAdState {
}

interface MarketingAdBox
{
}

class MarketingAdBox extends React.Component<marketingAdProps, marketingAdState>
{
    render(): React.ReactNode
    {
        return (
            <div>
                <h2 className="fs-30">Once your website has been optimized for the search engines, the next step is to put together a comprehensive Search Engine Marketing Strategy.</h2>
                <div className="badges text-center" style={{float: "right"}}>
                    <a href={"https://www.google.com/partners/agency?id=1771556376"} target="_blank" rel="nofollow noopener" style={{display: "block"}}>
                                <img src="https://www.gstatic.com/partners/badge/images/2021/PartnerBadgeClickable.svg" alt="Google Partner Badge" style={{maxWidth: "100px", marginBottom: "20px"}} />
                            </a>

                    <a href={"https://about.ads.microsoft.com/en-us/resources/microsoft-advertising-partner-program/partner-directory?Filter=1&amp;page=1&amp;Filter_Services=&amp;Filter_Location=US&amp;Filter_Industry=&amp;Filter_PartnerType=&amp;Filter_SearchText=Synergy%20Web%20Design%20%26%20Marketing"} className="bing-badge" target="_blank" rel="nofollow noopener" style={{padding: "2.1em"}}>
                        <img src="http://snwebdm.com/img/Partner-Badges-2022-Partner.png" alt="Bing Ads Accredited Professional" title="Bing Ads Accredited Professional" style={{maxWidth: "200px"}} />
                    </a>
                </div>
                <ul className="listIndent">
                    <li>SEO packages to fit your needs and budget, and we're committed to providing you with the highest level of service and support.</li>
                    <li>Comprehensive Digital Marketing plans.</li>
                    <li>Custom Portal to help you monitor where your website traffic is coming from.</li>
                </ul>
                <p>So don't wait - take the next step towards SEO success and partner with Synergy for the success of your Website. Contact us today to learn more about our services and how we can help you achieve your online goals.
                <br />
                Please fill out the contact form below and one of our Digital Marketing Specialists will contact you in 24 Hrs.</p>
            </div>
        )
    };
}

export default MarketingAdBox;