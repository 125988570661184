import React from "react";
import GlobalContext from "./GlobalContext";

interface listProps 
{
    getSites: Function;
    abortSiteQuerys: Function;
    sites: Array<string>;
    data?: any;
}
interface listState
{
    sites: Array<string>;
}
interface ListDisplayOnly
{
    abController: AbortController;
}

class ListDisplayOnly extends React.Component<listProps, listState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    componentDidMount()
    {
        this.props.getSites();
    }

    componentWillUnmount()
    {
        this.props.abortSiteQuerys();
    }

    render(): React.ReactNode
    {
        if (this.props.sites.length > 0)
        {
            return (
                <select id="url" name="url">
                        <option id="defaultSelectDelete">You may use this dropdown to select an existing Audit</option>
                    {
                        this.props.sites.map((site) => {
                            return (
                                <option key={site} value={site}>{site}</option>
                            );
                        })
                    }
                </select>
            );
        }
        return (
            <select id="url" name="url">
                <option id="defaultSelectDelete">You have no existing Audits available</option>
            </select>
        )
    };
}

export default ListDisplayOnly;