import React from "react";
import logo_white from "./images/logo-white.svg";

interface FooterProp {}
interface FooterState {
}

interface Footer {
    abController: AbortController;
}

class Footer extends React.Component<FooterProp, FooterState>
{   
    render(): React.ReactNode {
        return (
            <footer className="synergy-bg full-width w-100">
                    <p className="disclaimer container">You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any way exploit any such content, nor may you distribute any part of this content over any network, sell or offer it for sale, or use such content to construct any kind of database. You may not alter or remove any copyright or other notice from copies of the content on this page.</p>
                    <a href="https://snwebdm.com" target="_blank" rel="noreferrer"><img className="py-1 img-fluid" src={logo_white} alt="Snwebdm Logo"></img></a>
                    <p>Synergy Webdesign and Marketing SEO Services . 10970 S. Cleveland Ave. Ft. Myers, FL 33907</p>
            </footer>
        );
    };
}

export default Footer;