import React from "react";
import logo from './images/logo.svg';
import GlobalContext from "./GlobalContext";

interface LogoProps 
{
}
interface LogoState
{
}
interface Logo
{
}

class Logo extends React.Component<LogoProps, LogoState>
{

    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    render(): React.ReactNode
    {
        return (
            <div className="flex logoContainer">
                <img className="logo img-fluid align-self-center" src={logo} alt="logo" title="logo" onClick={this.context.goToHome}></img>
            </div>
        );
    };
}

export default Logo;