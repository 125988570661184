import React from "react";
import Count from './ts/count';
import GlobalContext from "./GlobalContext";

interface seoProps
{
    data: Count;
}
interface seoState
{
}

class ReportSiteImage extends React.Component<seoProps, seoState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    render(): React.ReactNode
    {
        return (
            <div className="reportSiteImage">
                <img src={this.props.data.siteImage} alt="Your Website's Front Page" />
            </div>
        )
    };
}

export default ReportSiteImage;