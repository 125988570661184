import axios from "axios";
import React from "react";
import GlobalContext from './GlobalContext';

interface ScanControlsProp {
    getSites?: Function;
}
interface ScanControlsState {}

class ScanControls extends React.Component<ScanControlsProp, ScanControlsState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    runScan()
    {
        if (this.context.loggedIn === false) {
            this.context.setGlobalState({register: true, fakeLoading: true});
            return;
        }
        let url: HTMLInputElement | null = document.querySelector('input[name="url"]');
        if (url === null) {
            console.warn("url input doesn't exist");
            return;
        }
        if (url.value === '') {
            return;
        }
        this.context.setGlobalState({loading: true});
        axios.get(process.env.REACT_APP_ROOT + '/php/report/checkScansRemaining?userid=' + this.context.userid).then(response => {
            if (response.data.scans_remaining > 0) {
                axios.post(process.env.REACT_APP_ROOT + '/php/report/run', {"url": url?.value, "userid": this.context.userid}).then(response => {
                    gtag('event', 'purchase', {'scanURL': url?.value, 'currency': 'USD', 'value': 9.95, 'transaction_id': 'admin account'});
                    // console.log(response.data);
                    // @ts-ignore
                    // console.log('Running ' + url?.value);
                    if (typeof this.props.getSites !== 'undefined') {
                        this.props.getSites();
                    }
                    // @ts-ignore
                    this.context.setSelectedSite(url?.value);
                }).catch(error => {
                    console.warn(error.data);
                }) 
            } else {
                console.log('No scans remaining');
                this.context.setGlobalState({queuedSite: url?.value, contact: false, login: false, dashboard: false, homePage: false, loading: false, fakeLoading: true, buyScans: true, register: false, data: {}, checking: false});
            }
        }).catch(error => {
            console.warn(error.data);
        });
    }

    render(): React.ReactNode {
        return (
            <div id="scan-controls">
                <input type="text" name="url" id="url_input" placeholder="Example.com" />
                <button id="run" onClick={this.runScan.bind(this)}>Run Audit</button>
            </div>
        );
    };
}

export default ScanControls;