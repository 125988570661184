import React from "react";
import axios from "axios";
import GlobalContext from './GlobalContext';
import Loading from "./Loading";

interface LoginProp {}
interface LoginState {
    loading: boolean;
}

interface Login {
    abController: AbortController;
}

class Login extends React.Component<LoginProp, LoginState>
{   
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    constructor(props: LoginProp)
    {
        super(props);
        this.state = {
            loading: false
        };
    }
    componentDidMount()
    {
        this.context.setGlobalState({loading: false});
    }

    abortSiteQuerys = () =>
    {
        if (this.abController) {
            this.abController.abort();
        }
    }

    authenticate = () =>
    {
        this.abortSiteQuerys();
        this.abController = new AbortController();
        let data: FormData = new FormData();
        let email: HTMLInputElement | null = document.querySelector('input[name="email"]');
        let password: HTMLInputElement | null = document.querySelector('input[name="password"]');

        if (!email || !password) {
            return;
        }

        data.append('email', email.value);
        data.append('password', password.value);

        this.setState({loading: true});

        axios.post(process.env.REACT_APP_ROOT + '/php/auth/authenticate', data, {signal: this.abController.signal,withCredentials: true}).then(response => {
            let errors: NodeListOf<HTMLElement> | null = document.querySelectorAll('.error');
            for (let i = 0; i < errors.length; i++) {
                errors[i].remove();
            }
            // console.log(response.data);
            if (response.data.success) {
                this.setState({loading: false});
                this.context.setGlobalState({login: false, loggedIn: true, dashboard: true, userid: response.data.userid});
                window.dataLayer.push({'event': 'login'});
            }
            if (response.data.error) {
                this.setState({loading: false});
                let loginInnerContainer = document.querySelector('.loginInnerContainer');
                loginInnerContainer?.insertAdjacentHTML('afterbegin', '<div class="error">' + response.data.error + '</div>');
            }
            // this.setState({sites:response.data});
        }).catch(error => {
            console.error(error);
        });
    }

    register = () =>
    {
        this.context.setGlobalState({fakeLoading: true, login: false, register: true});
    }

    togglePassword = () =>
    {
        let passwordField: HTMLElement | null = document.querySelector('#password');
        if (passwordField !== null)
        {
            if (passwordField.getAttribute('type') === 'text')
            {
                passwordField.setAttribute('type', 'password');
                return;
            }
            if (passwordField.getAttribute('type') === 'password')
            {
                passwordField.setAttribute('type', 'text');
                return;
            }
        }
    }

    componentWillUnmount()
    {
        this.abortSiteQuerys();
    }

    render(): React.ReactNode {
        return (
            <div id="container" className="flex column flex-w-center flex-h-center">
                <div className="loginContainer">
                    <div className="loginInnerContainer flex column">
                        <h1>Login</h1>
                        <label htmlFor="email" className="loginLabel">E-Mail</label>
                        <input type="text" id="email" name="email" className="loginInput"></input>
                        <div className="passwordContainer flex column">
                            <label htmlFor="password" className="loginLabel">Password</label>
                            <input type="password" id="password" name="password" className="loginInput"></input>
                            <button onClick={this.togglePassword} className="togglePassword" tabIndex={-1}><i className="fa fa-eye"></i></button>
                        </div>
                        <button onClick={this.authenticate} className="confirmationButton mx-auto">{this.state.loading && <Loading width="19px" height="19px" text="" color="black" />} {!this.state.loading && "Login"}</button>
                        <br />
                        <button onClick={this.register} className="mx-auto fake-link">Don't have an account?<br />Click Here to Register!</button>
                    </div>
                </div>
            </div>
        );
    };
}

export default Login;