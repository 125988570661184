import React from "react";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import axios from "axios";
import GlobalContext from "./GlobalContext";
import { TokenResult, VerifyBuyerResponseDetails } from "@square/web-payments-sdk-types";
import Loading from "./Loading";

interface PaymentProps {
    getSites?: Function;
}
interface PaymentState {}

interface Payment
{
    paymentEventCheck: boolean;
    paymentToken: string | undefined;
}

class Payment extends React.Component<PaymentProps, PaymentState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    paymentEventCheck: boolean = false;

    componentDidMount(): void
    {
        if (this.paymentEventCheck === false)
        {
            const url = {
                value: this.context.queuedSite
            };
            window.dataLayer.push({'event': 'begin_checkout', 'scanURL': url?.value});
            this.paymentEventCheck = true;
        }
    }

    runScan()
    {
        if (this.context.loggedIn === false) {
            this.context.setGlobalState({register: true, fakeLoading: true});
            return;
        }
        const url = {
            value: this.context.queuedSite
        };
        this.context.setGlobalState({loading: true});
        axios.get(process.env.REACT_APP_ROOT + '/php/report/checkScansRemaining?userid=' + this.context.userid).then(response => {
            if (response.data.scans_remaining > 0) {
                axios.post(process.env.REACT_APP_ROOT + '/php/report/run', {"url": url?.value, "userid": this.context.userid}).then(response => {
                    gtag('event', 'purchase', {'scanURL': url?.value, 'currency': 'USD', 'value': 9.95, 'transaction_id': this.paymentToken});
                    // console.log(response.data);
                    // @ts-ignore
                    // console.log('Running ' + url?.value);
                    if (typeof this.props.getSites !== 'undefined') {
                        this.props.getSites();
                    }
                    // @ts-ignore
                    this.context.setSelectedSite(url?.value);
                }).catch(error => {
                    console.warn(error.data);
                }) 
            } else {
                console.log('No scans remaining');
                this.context.setGlobalState({contact: false, login: false, dashboard: false, homePage: false, loading: false, fakeLoading: true, buyScans: true, register: false, data: {}, checking: false});
            }
        }).catch(error => {
            console.warn(error.data);
        });
    }

    postPayment = (token: TokenResult, buyer: VerifyBuyerResponseDetails | null | undefined) => {
        this.context.setGlobalState({loading: true});
        let data = {userid: this.context.userid, paymentToken: token.token, buyer: buyer};
        this.paymentToken = token.token;
        axios.post(process.env.REACT_APP_ROOT + '/php/Square/setPayment', data, {withCredentials: true}).then(response => {
            if (response.data.payment) {
                this.context.setGlobalState({loading: false, buyScans: false});
                this.runScan();
            }
        });
    }
    render(): React.ReactNode
    {
        return (
            <div className="paymentContainer flex">
                <div className="container">
                    <h1>Only $9.95 to start scanning your site!</h1>
                    <PaymentForm
                        /**
                         * Identifies the calling form with a verified application ID generated from
                         * the Square Application Dashboard.
                         */
                        applicationId="sq0idp-M16WIQdHCe8X-b3_Bc02Aw"
                        /**
                         * Invoked when payment form receives the result of a tokenize generation
                         * request. The result will be a valid credit card or wallet token, or an error.
                         */
                        cardTokenizeResponseReceived={(token, buyer) => {
                            // console.info({ token, buyer });
                            this.postPayment(token, buyer);
                        }}
                        /**
                         * This function enable the Strong Customer Authentication (SCA) flow
                         *
                         * We strongly recommend use this function to verify the buyer and reduce
                         * the chance of fraudulent transactions.
                         */
                        // createVerificationDetails={() => ({
                        //     amount: '1.00',
                        //     /* collected from the buyer */
                        //     billingContact: {
                        //         addressLines: ['123 Main Street', 'Apartment 1'],
                        //         familyName: 'Doe',
                        //         givenName: 'John',
                        //         countryCode: 'GB',
                        //         city: 'London',
                        //     },
                        //     currencyCode: 'GBP',
                        //     intent: 'CHARGE',
                        // })}
                        /**
                         * Identifies the location of the merchant that is taking the payment.
                         * Obtained from the Square Application Dashboard - Locations tab.
                         */
                        locationId="AF8PDNHKMJWRD"
                    >
                        <CreditCard />
                    </PaymentForm>
                </div>
            </div>
        );
    };
}

export default Payment;