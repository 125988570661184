import React from "react";

interface gridProps
{
    children?: React.ReactNode;
    columns: number;
    rows: number;
    columnGap: string;
    rowGap: string;
}
interface gridState {
    mobile: boolean;
}

interface Grid
{}

class Grid extends React.Component<gridProps, gridState>
{
    static defaultProps = {
        columns: 1,
        rows: 1,
        columnGap: 0,
        rowGap: 0
    };
    constructor(props: any)
    {
        super(props);
        if (window.innerWidth < 1070)
        {
            this.state = {mobile: true};
        }
        else
        {
            this.state = {mobile: false};
        }
    }
    componentDidMount(): void
    {
        window.addEventListener('resize', this.resize);
    }
    componentWillUnmount(): void {
        window.removeEventListener('resize', this.resize);
    }
    resize = () =>
    {
        if (window.innerWidth < 1070)
        {
            this.setState({mobile: true});
        }
        if (window.innerWidth >= 1070)
        {
            this.setState({mobile: false});
        }
    }
    render(): React.ReactNode
    {
        let fraction = "1fr ";
        let classes = "grid";
        if (this.state.mobile)
        {
            classes += " mobile";
        }
        return (
            <div style={{gridTemplateColumns: fraction.repeat(this.props.columns), gridTemplateRows: fraction.repeat(this.props.rows), gridRowGap: this.props.rowGap, gridColumnGap: this.props.columnGap}} className={classes}>
                {this.props.children}
            </div>
        )
    };
}

export default Grid;