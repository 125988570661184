import React from "react";
import Logo from "./Logo";
import ScanUI from "./ScanUI";
import GlobalContext from "./GlobalContext";

interface HeaderProps {
    displayScanUI: boolean;
}
interface HeaderState {}

interface Header
{}

class Header extends React.Component<HeaderProps, HeaderState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    static defaultProps = {
        displayScanUI: false
    };
    render(): React.ReactNode
    {
        return (
            <div className="headerContainer w-100">
                <div className="headerInnerContainer w-100 container">
                    <Logo />
                    <div className="flex flex-h-center loginButtonContainer">
                        <button onClick={this.context.goToHome} className="loginButton">Home</button>
                        { this.context.loggedIn && <button className="loginButton" onClick={this.context.goToDashboard}>Your Reports</button> }
                        { this.context.loggedIn && <button onClick={this.context.goToContact} className="loginButton">Contact Us</button> }
                        { this.context.loggedIn && <button className="loginButton" onClick={this.context.logout}>Logout</button> }
                        { this.context.loggedIn === false && <button className="loginButton" onClick={this.context.goToLogin}>Login</button> }
                    </div>
                </div>
            </div>
        );
    };
}

export default Header;