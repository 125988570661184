import React from 'react';
import './App.css';
import './style.css';
import Report from './Report';
import axios from 'axios';
import Count from './ts/count';
import Checking from './Checking';
import Loading from './Loading';
import Login from './Login';
import Register from './Register';
import GlobalContext from './GlobalContext';
import Footer from './Footer';
import Payment from './Payment';
import History from './ts/history';
import Header from './Header';
import Contact from './Contact';
import Dashboard from './Dashboard';

declare global {
    interface Window {
        dataLayer: any[];
    }
    function gtag(event: String, eventName: String, eventData: Object): void;
}

interface appProps {}
interface appState
{
    data: any;
    loading: boolean;
    fakeLoading: boolean;
    checking: boolean;
    selectedSite: String;
    queuedSite: String;
    loggedIn: boolean;
    login: boolean;
    register: boolean;
    userid: number | null;
    darkMode: boolean;
    buyScans: boolean;
    printMode: boolean;
    contact: boolean;
    dashboard: boolean;
}
interface App
{
    abController: AbortController;
    global: {};
    history: History;
}

class App extends React.Component<appProps, appState>
{
    constructor(props: any)
    {
        super(props);
        this.abController = new AbortController();
        this.requestData = this.requestData.bind(this);
        this.state = {data: {}, loading: true, fakeLoading: true, checking: false, selectedSite: '', queuedSite: '', login: false, loggedIn: false, register: false, userid: null, darkMode: false, buyScans: false, contact: false, dashboard: false, printMode: false};
        this.history = new History(this.state);
        axios.get(process.env.REACT_APP_ROOT + '/php/auth/checkSession', {signal: this.abController.signal,withCredentials: true}).then(response => {
            // console.log(response.data);
            if (response.data.userid) {
                this.setState({data: {}, loading: false, checking: false, selectedSite: '', loggedIn: true, userid: response.data.userid});
            } else {
                this.setState({loading: false});
            }
            // this.setState({sites:response.data});
        }).catch(error => {
            console.error(error);
        });
    }

    componentDidMount(): void
    {
        window.addEventListener('popstate', this.historyListenerFunction);
        window.addEventListener('beforeprint', this.handlePrint);
        window.addEventListener('afterprint', this.handlePrint);
    }

    componentWillUnmount(): void {
        window.removeEventListener('beforeprint', this.handlePrint);
        window.removeEventListener('afterprint', this.handlePrint);
    }

    handlePrint = (event: Event) =>
    {
        if (event.type === 'beforeprint')
        {
            this.setState({printMode: true});
        }
        if (event.type === 'afterprint')
        {
            this.setState({printMode: false});
        }
    }

    requestData(event: any): React.ChangeEventHandler<HTMLSelectElement>
    {
        let site = event.target.value;
        this.setSelectedSite(site);
        return event;
    }

    setSelectedSite = (site: String) =>
    {
        this.setState({
            loading:true,
            buyScans: false
        });
        axios.get(process.env.REACT_APP_ROOT + '/php/report/open?q=' + site + '&userid=' + this.state.userid, {signal: this.abController.signal}).then(response => {
            // console.log(response.data);
            if (response.data.error) {
                this.setState({
                    data: {},
                    loading: false,
                    fakeLoading: false,
                    checking: true,
                    selectedSite: site
                });
                // Make sure to clean-up the loader as everything rerenders
                return;
            }
            let newCount;
            if (typeof response.data.refined === 'undefined')
            {
                newCount = new Count(response.data);
                axios.post(process.env.REACT_APP_ROOT + '/php/report/refineSiteFormat', {userid: this.state.userid, url: site, refinedData: newCount}, {withCredentials: true}).then(response => {
                }).catch(error => {
                    console.error(error);
                });
            } else {
                newCount = response.data.refined;
            }
            this.setState({
                data: newCount,
                loading: false,
                fakeLoading: false,
                checking: false,
                dashboard: false,
                selectedSite: site,
                buyScans: false
            }, this.pushHistory);
            let defaultSelectOption = document.querySelector('#defaultSelectDelete')
            if (defaultSelectOption === null) {
                console.warn('Website List is missing the default option');
                return;
            }
            defaultSelectOption.remove();
        }).catch(error => {
            console.error(error);
        });
    }

    setGlobalState = (state: appState | object | void, callback: () => void = () => {}): void =>
    {
        if (typeof callback === 'function') {
            this.setState(state as appState, callback);
            this.updateGlobals();
            return;
        }
        this.setState(state as appState);
        this.updateGlobals();
    }

    updateGlobals = () =>
    {
        this.global = {loading: this.state.loading, fakeLoading: this.state.fakeLoading, printMode: this.state.printMode, userid: this.state.userid, selectedSite: this.state.selectedSite, queuedSite: this.state.queuedSite, setGlobalState: this.setGlobalState, pushHistory: this.pushHistory, setSelectedSite: this.setSelectedSite, siteChange: this.requestData, goToLogin: this.goToLogin, goToHome: this.goToHome, goToContact: this.goToContact, goToDashboard: this.goToDashboard, login: true, loggedIn: this.state.loggedIn, logout: this.logout};
    }

    historyListenerFunction = (event: PopStateEvent) => {
        this.history.go(event.state?.historyIndex);
        this.setGlobalState(this.history.get());
    }

    pushHistory = () =>
    {
        this.history.push(this.state);
        window.history.pushState({"historyIndex": this.history._currentHistoryIndex}, '', window.location.href);
    }

    goToLogin = () =>
    {
        this.setGlobalState({fakeLoading: true, login: true}, this.pushHistory);
    }

    logout = () =>
    {
        this.setGlobalState({fakeLoading: true, loggedIn: false, userid: 0, selectedSite: '', data: {}, checking: false, buyScans: false});
    }

    goToHome = () =>
    {
        this.setGlobalState({contact: false, buyScans: false, login: false, dashboard: false, homePage: false, fakeLoading: true, register: false, data: {}, checking: false}, this.pushHistory);
    }

    goToContact = () =>
    {
        this.setGlobalState({contact: true, buyScans: false, login: false, dashboard: false, homePage: false, fakeLoading: true, register: false, data: {}, checking: false}, this.pushHistory);
    }
    goToDashboard = () =>
    {
        this.setGlobalState({contact: false, buyScans: false, login: false, dashboard: true, homePage: false, fakeLoading: true, register: false, data: {}, checking: false}, this.pushHistory);
    }

    render(): React.ReactNode {
        this.updateGlobals();

        if ((this.state.loading || this.state.fakeLoading)) {
            return (
                <GlobalContext.Provider value={this.global}>
                    <div className="App">
                        <Header />
                        <Loading />
                        <Footer />
                    </div>
                </GlobalContext.Provider>
            )
        }

        if (this.state.checking) {
            return (
                <GlobalContext.Provider value={this.global}>
                    <div className="App">
                        <Header />
                        <Checking setSelectedSite={this.setSelectedSite} />
                        <Footer />
                    </div>
                </GlobalContext.Provider>
            )
        }

        if (this.state.dashboard) {
            return (
                <GlobalContext.Provider value={this.global}>
                    <div className="App">
                        <Header />
                        <Dashboard />
                        <Footer />
                    </div>
                </GlobalContext.Provider>
            )
        }

        if (this.state.contact) {
            
            return (
                <GlobalContext.Provider value={this.global}>
                    <div className="App">
                        <Header />
                        <Contact />
                        <Footer />
                    </div>
                </GlobalContext.Provider>
            )
        }
        
        if (this.state.register) {
            return (
                <GlobalContext.Provider value={this.global}>
                    <div className="App">
                        <Header />
                        <Register />
                        <Footer />
                    </div>
                </GlobalContext.Provider>
            )
        }
        
        if (this.state.login) {
            return (
                <GlobalContext.Provider value={this.global}>
                    <div className="App">
                        <Header />
                        <Login />
                        <Footer />
                    </div>
                </GlobalContext.Provider>
            )
        }


        if (this.state.buyScans) {
            return (
                <GlobalContext.Provider value={this.global}>
                    <div className="App">
                        <Header />
                        <Payment />
                        <Footer />
                    </div>
                </GlobalContext.Provider>
            )
        }

        return (
            <GlobalContext.Provider value={this.global}>
                <div className="App">
                    <Header displayScanUI={this.state.selectedSite === '' ? false : true} />
                    <Report data={this.state.data} />
                    <Footer />
                </div>
            </GlobalContext.Provider>
        );
    }
}

export default App;
