const ProgressBar = require('progressbar.js');

class Progress
{
    _container: string;
    _progressContainerClass: string;
    _settings: Object;
    _loader: any;

    constructor(container: string, progressContainerClass: string, settings: Object)
    {
        this._container = container;
        this._progressContainerClass = progressContainerClass;
        if (typeof settings != "undefined") {
            this._settings = settings;
        } else {
            this._settings = {
                color: "#FFFFFF"
            }
        }
    }

    public show(): void
    {
        if (typeof this._loader !== 'undefined') {
            this.hide();
        }
        let container = document.querySelector('.' + this._container);
        if (!container) {
            console.warn("Container not found");
            return;
        }
        container.insertAdjacentHTML('afterbegin', this.template());
        this._loader = new ProgressBar.Circle('.' + this._progressContainerClass, this._settings);

        this._loader.animate('1',{}, this.loop.bind(this));
    }

    public hide(): void
    {
        if (typeof this._loader !== 'undefined') {
            this._loader.destroy();
            this._loader = undefined;
        } else {
            console.warn("There is no progress shown currently");
            return;
        }

        document.querySelector('.' + this._progressContainerClass)?.remove();
    }

    public set(value: number): void
    {
        this._loader.animate(value);
    }

    public setText(value: string): void
    {
        this._loader.setText(value);
    }

    private loop(): void
    {
        if (typeof this._loader === 'undefined') {
            return;
        }
        if (this._loader.value() === 1) {
            this._loader.animate('0',{}, this.loop.bind(this));
            return;
        }
        this._loader.animate('1',{}, this.loop.bind(this));
        return;
        
    }

    private template(): string
    {
        let template = `<div class="${this._progressContainerClass}"></div>`

        return template;
    }
}
export default Progress;