import React from "react";
import Count from './ts/count';

interface seoProps {
    data: Count;
    type: string;
    setReportState: Function;
}
interface seoState
{
}

class ReportContent extends React.Component<seoProps, seoState>
{
    render(): React.ReactNode
    {
        // We have to put these the categories/groups together as arrays for JSX to compile them. So we start from the deepest structure and work our way out.
        // Meaning that if you want to look at the div structure you should read this file from the bottom up for the HTML

        // Have to setup conditionals before the render return
        let mainLoop: Array<JSX.Element> = [];
        // Check to make sure that the data isn't empty
        if (Object.keys(this.props.data).length > 0 && (this.props.type !== undefined && (this.props.type === "performance" || this.props.type === "accessibility"  || this.props.type === "bestPractices"  || this.props.type === "seo" ))) {
            let groups = this.props.data.groupedData;
            let categoryLoop: Array<JSX.Element> = [];
            let type = this.props.type;

            // Main loop for the seo header
            if (groups[type as keyof typeof groups].groupIssueCount > 0) {
                mainLoop.push(
                    <div>
                        <div className="issues">
                            {categoryLoop}
                        </div>
                    </div>
                );
            }

            // Loop through the seo property for each audit type
            // performance -> audit groups or categories
            Object.keys(groups[type as keyof typeof groups]).forEach((groupKey, index) => {
                // Clear audit Type Loop
                let auditTypeLoop: Array<JSX.Element> = [];
                // Store the current nesting to a variable for ease of use. 
                // This will be a variable that contains all the urls in a audit type
                let auditTypes = groups[type as keyof typeof groups][groupKey];

                // Check that we even have reason to go through the category
                if (auditTypes.issueCount > 0) {    

                    if (typeof (auditTypes ||{}).title === 'undefined') {
                        return;
                    }
                    let cleanGroupTitle = auditTypes.title.replace(/(<([^>]+)>)/gi, "");
                    if (typeof (auditTypes ||{}).description === 'undefined') {
                        auditTypes.description = "There is no Descriptions for this category";
                    }
                    let cleanGroupDescription = auditTypes.description.replace(/(<([^>]+)>)/gi, "");
                    cleanGroupDescription = cleanGroupDescription.replace(/\[(.*)\]\((.*)\)/gi, "<a target='_blank' href='$2'>$1</a>");
                    // Push them into an issueLoop variable which is called in the higher up hierarchy urlLoop
                    categoryLoop.push(
                        <div>
                            <div className="issue-type collapse-group open">
                                <div className="issue-title collapse-controller">
                                    <h1>
                                        {cleanGroupTitle}
                                    </h1>
                                    <span className="issue-count">{auditTypes.issueCount !== undefined ? auditTypes.issueCount + ' Issues' : ''}</span>
                                    <i className="fa-solid fa-chevron-down accordian-arrow"></i>
                                </div>
                                <div className="collapse-group urls-group">
                                    <div className="issue-description">
                                            <h3 dangerouslySetInnerHTML={{__html: cleanGroupDescription}}></h3>
                                    </div>
                                    {auditTypeLoop}
                                </div>
                            </div>
                        </div>
                    );
                    // loop through audit groups or categories -> urls
                    Object.keys(auditTypes).forEach((auditKey, index) => {
                        // Clear url Loop
                        let urlLoop: Array<JSX.Element> = [];
                        // Make sure to not run anything on the title key
                        if (auditKey === 'title') {
                            return;
                        }
                        if (auditKey === 'description') {
                            return;
                        }
                        if (auditKey === 'issueCount') {
                            return;
                        }
                        // Grab the first url to use to grab the description and title
                        let homeUrl: any = Object.keys(auditTypes[auditKey])[0];

                        if (typeof (auditTypes[auditKey][homeUrl] ||{}).title === 'undefined') {
                            return;
                        }
                        let cleanTitle = auditTypes[auditKey][homeUrl].title.replace(/(<([^>]+)>)/gi, "");
                        if (typeof (auditTypes[auditKey][homeUrl] ||{}).description === 'undefined') {
                            return;
                        }
                        let cleanDescription = auditTypes[auditKey][homeUrl].description.replace(/(<([^>]+)>)/gi, "");
                        cleanDescription = cleanDescription.replace(/\[(.*)\]\((.*)\)/gi, "<a target='_blank' href='$2'>$1</a>");

                        // We want to minimize as much loop nesting as we can so we actually have the server when running through the data count how many
                        // issues there are and put it in at a higher level so that the loops can be in sync without having to be nested
                        // This will be called in the mainloop
                        // console.log(auditKey);
                        // if (auditKey == "no-vulnerable-libraries") {
                        //     console.log(auditTypes[auditKey]);
                        // }
                        if (auditTypes[auditKey]['IssueCount'] > 0) {
                            auditTypeLoop.push(
                                <div className="issue-type collapse-group open">
                                    <div className="issue-title collapse-controller">
                                        <h1>
                                            {cleanTitle}
                                        </h1>
                                        <span className="issue-count">{auditTypes[auditKey]['IssueCount'] !== undefined ? auditTypes[auditKey]['IssueCount'] + ' Issues' : ''}</span>
                                        <i className="fa-solid fa-chevron-down accordian-arrow"></i>
                                    </div>
                                    <div className="collapse-group urls-group">
                                        <div className="issue-description">
                                            <h3 dangerouslySetInnerHTML={{__html: cleanDescription}}></h3>
                                        </div>
                                        {urlLoop}
                                    </div>
                                </div>
                            );
                        }

                        let auditUrls = auditTypes[auditKey];

                        // Loop through urls to actual issues
                        Object.keys(auditUrls).forEach((urlKey, index) => {
                            let issueLoop: Array<JSX.Element> = [];
                            // We have to put these together as arrays for JSX to compile them. So we start from the deepest structure and work our way out.
                            // Meaning that if you want to look at the div structure you should read this file from the bottom up for the HTML
                            let auditUrl = auditUrls[urlKey];
                            if (auditUrl.ourIssueCount > 0) {
                                // Loop through all the detail items on a specific url issue type
                                if ((auditUrl.details ||{}).items !== undefined) {
                                    Object.keys(auditUrl.details.items).forEach((itemKey, index) =>{
                                        // Can't go further in the nested array if it doesn't exist so check the container details first then nest down into items
                                        // Super secret way of handling this TypeError (http://web.archive.org/web/20161108071447/http://blog.osteele.com/posts/2007/12/cheap-monads/)
                                        // You can nest them like below to grab a property without an error. It will just return null if any of the check fail
                                        // Helps save resources on variables
                                        // ((Object.property ||{}).property2 ||{}).property3
            
                                        // We want to grab the details and store them individually
                                        let item = auditUrl.details.items[itemKey];
                                        let itemHTML: Array<JSX.Element> = [];
                                        if (typeof item !== 'undefined') {
                                            // DOM Size
                                            if (typeof item.element !== 'undefined') {
                                                itemHTML.push(
                                                    <span>{item.statistic} {item.value}</span>
                                                );
                                                item = itemHTML;
                                            }
                                            // Third Party
                                            if (typeof item.entity !== 'undefined') {
                                                itemHTML.push(
                                                    <span>{item.entity.text}<br /><br />
                                                    {item.entity.url}<br /><br />
                                                    Blocked Time: {Math.round(item.blockingTime) / 1000} s<br /><br />
                                                    Transfer Size: {item.transferSize} KB<br /><br />
                                                    Total Main Thread Time: {Math.round(item.mainThreadTime) / 1000}s
                                                    </span>
                                                );
                                                item = itemHTML;
                                            }
                                            // Cache
                                            if (typeof item.wastedPercent !== 'undefined') {
                                                itemHTML.push(
                                                    <span>{item.url}<br /><br />
                                                    TotalBytes: {item.totalBytes} KB<br /><br />
                                                    Potential Savings: {item.wastedBytes} KB<br /><br />
                                                    Wasted Percent: {item.wastedPercent}%
                                                    </span>
                                                );
                                                item = itemHTML;
                                            }
                                            if (typeof item.wastedBytes !== 'undefined') {
                                                itemHTML.push(
                                                    <span>{item.url}<br /><br />
                                                    TotalBytes: {item.totalBytes} KB<br /><br />
                                                    Potential Savings: {item.wastedBytes} KB
                                                    </span>
                                                );
                                                item = itemHTML;
                                            }
                                            // Main Thread Issue
                                            if (typeof item.group !== 'undefined') {
                                                item = item.groupLabel + " Duration: " + Math.round(item.duration) / 1000 + "s";
                                            }
                                            // JS Libraries vul
                                            if (typeof item.vulnCount !== 'undefined') {
                                                item = item.detectedLib.text + " # of Issues: " + item.vulnCount + " " + item.highestSeverity;
                                            }
                                            // Things like cross-link rels
                                            if (typeof item.outerHTML !== 'undefined') {
                                                item = item.outerHTML;
                                            }
                                            // Urls for assets
                                            if (typeof item.url !== 'undefined') {
                                                item = item.url;
                                            }
                                            // Main issue area here for html snippets
                                            if (typeof (item.node ||{}).snippet !== 'undefined') {
                                                item = item.node.snippet;
                                            }
                                            // Tap Targets
                                            if (typeof (item.tapTarget ||{}).snippet !== 'undefined') {
                                                item = item.tapTarget.snippet;
                                            }
                                            // Nothing
                                            if (item === auditUrl.details.items[itemKey]) {
                                                item = '';
                                            }
                                        } else {
                                            item = '';
                                        }
                                        // Push them into an issueLoop variable which is called in the higher up hierarchy urlLoop
                                        issueLoop.push(
                                            <div>
                                                <div className="issue-type collapse-group open">
                                                    <div className="issue-title collapse-controller">
                                                        <p key={itemKey}>
                                                            {item}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    });
                                } else {
                                    // For the performance metrics that don't have items
                                    let item = auditUrl;
                                    if (typeof item !== 'undefined') {
                                        if (typeof item.displayValue !== 'undefined') {
                                            item = item.displayValue;
                                        }
                                        if (item === auditUrl) {
                                            item = item.title;
                                        }
                                    } else {
                                        item = '';
                                    }
                                    issueLoop.push(
                                        <div>
                                            <div className="issue-type collapse-group open">
                                                <div className="issue-title collapse-controller">
                                                    <p>{auditKey}</p>
                                                    <p>{item}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                            // Check to make sure that it's a category that has failed then push the div into the loop to be called in the auditTypeLoop
                            if (auditUrl.score < 1 && auditUrl.scoreDisplayMode.toLowerCase() === "binary") {
                                urlLoop.push(
                                    <div className="collapse-group open">
                                        {/* Holds the url names */}
                                        <div className="url-group collapse-controller">
                                            <h1>{urlKey}</h1>
                                            <span className="issue-count">{auditUrl.ourIssueCount !== undefined ? auditUrl.ourIssueCount + ' Issues' : ''}</span>
                                            <i className="fa-solid fa-chevron-down accordian-arrow"></i>
                                        </div>
                                        <div className="collapse-group">
                                            {/* Issues themselves go into here */}
                                            <div className="issue-details-group collapse-controller">
                                                {issueLoop}
                                            </div>
                                            <div className="collapse-group">
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else if (auditUrl.score < 0.7 && auditUrl.scoreDisplayMode.toLowerCase() === "numeric") {
                                urlLoop.push(
                                    <div className="collapse-group open">
                                        {/* Holds the url names */}
                                        <div className="url-group collapse-controller">
                                            <h1>{urlKey}</h1>
                                            <span className="issue-count">{auditUrl.ourIssueCount !== undefined ? auditUrl.ourIssueCount + ' Issues' : ''}</span>
                                            <i className="fa-solid fa-chevron-down accordian-arrow"></i>
                                        </div>
                                        <div className="collapse-group">
                                            {/* Issues themselves go into here */}
                                            <div className="issue-details-group collapse-controller">
                                                {issueLoop}
                                            </div>
                                            <div className="collapse-group">
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        });
                    });
                }
            });
        }
        // Rendering out the actual file div containg all the loops by calling mainloop
        return (
            <div className={this.props.type}>
                {mainLoop}
            </div>
        );
    };
}

export default ReportContent;