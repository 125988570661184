import React from "react";
import List from "./List";
import ScanControls from './ScanControls';
import axios from "axios";
import GlobalContext from './GlobalContext';
import ListDisplayOnly from "./ListDisplayOnly";

interface ScanUIProp {
    listOnly?: boolean;
    listDisplayOnly?: boolean;
}
interface ScanUIState {
    sites: Array<string>;
}

interface ScanUI {
    abController: AbortController;
}

class ScanUI extends React.Component<ScanUIProp, ScanUIState>
{
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;
    constructor(props: any)
    {
        super(props);
        this.state = {sites: ['example.com']};
    }

    static defaultProps = {
        listOnly: false
    };

    getSites = () =>
    {
        this.abController = new AbortController();
        // console.log(this.props);
        axios.get(process.env.REACT_APP_ROOT + '/php/report/sites?userid=' + this.context.userid, {signal: this.abController.signal}).then(response => {
            // console.log(response.data);
            this.setState({sites:response.data});
        }).catch(error => {
            console.error(error);
        });
    }
    
    componentDidUpdate()
    {
        let selectedSite: HTMLSelectElement | null = document.querySelector('option[value="' + this.context.selectedSite + '"]');
        if (selectedSite === null) {
            console.warn("Selected Site not found in dropdown");
            return;
        }
        // @ts-ignore
        selectedSite.selected = true;
    }

    abortSiteQuerys = () =>
    {
        this.abController.abort();
    }

    componentWillUnmount()
    {
        this.abortSiteQuerys();
    }

    render(): React.ReactNode {
        if (this.props.listOnly) {
            return (
                <List sites={this.state.sites} getSites={this.getSites} abortSiteQuerys={this.abortSiteQuerys} />
            )
        }
        if (this.props.listDisplayOnly) {
            return (
                <ListDisplayOnly sites={this.state.sites} getSites={this.getSites} abortSiteQuerys={this.abortSiteQuerys} />
            );
        }
        return (
                <div id="run_container">
                    <List sites={this.state.sites} getSites={this.getSites} abortSiteQuerys={this.abortSiteQuerys} />
                    <ScanControls getSites={this.getSites} />
                </div>
        );
    };
}

export default ScanUI;